.intern-network-see-all {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Dialog_closeButtonUpperRight {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
}


.seeAllAlert__box {
	position: relative;
	height: 100vh;
	top: 34px;
	bottom: 50px;
	margin: 0 0 100px 0;
	padding: 25px;
	opacity: 1;
	background: #fff;
	border-radius: 8px;
	display: table;
}

.back-btn {
	margin-right: 15px;
	margin-top: 15px;
	color: #5f60ff;
	font-size: 16px;
	cursor: pointer;
}

.see-all-slider {
	height: 1250px;
	position: relative;
	width: 1000px;
}

.see-all-slider-inner {
	position: absolute;
	top: 0px;
	left: 0px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: grid !important;
	grid-template-columns: repeat(3, 1fr);
	gap: 5px;
	pointer-events: none;
	transition: 0s ease-in;

}

.see-all-title {
	padding-bottom: 10px;
}
