
@import "src/css/Variables";


.WeConnectAlert__box {
    position: relative;
    height: 100vh;
    top: 34px;
	bottom: 50px;
    margin: 0 0 100px 0;
    padding: 25px;
    opacity: 1;
	background: #fff;
    border-radius: 8px;
	display: table;
}

.weConnect_title{
    font-size: 24px;
}

.weConnectDlog-info{
    margin: 0 0 20px 0;
    color: #00000099;
}

.weConnect{
    display: flex;
    flex: 1 0 270px;
    font-weight: 500;
}

#weConnect_filterWrapper {
    width: 770px;
    height: 40px;
    padding: 0 15px;
    justify-content: space-between;
}

#filter-group{
    font-weight: 400;
    font-size: 16px;
}

#weConnect_select {
    padding: 0 2px 0 8px;
    border-radius: 4px;
    box-shadow: none;
    flex: 1;
    margin: 0 5px;
    width: 230px;
    height: 40px;
}


.weConnect_carousel {
    margin: 20px 100px 100px 100px;
    padding: 10px 30px 25px 30px;
    border-radius: 1px;
}

.card {
	cursor: pointer;
}

.intern-cards {
	height: 180px;
}

.slack-url {
	flex-shrink: 0;
	background: url("../../src_static/assets/slack_icon.svg") no-repeat center;
	z-index: 10;
	pointer-events: auto;
	cursor: pointer;
	height: 30px;
	width: 30px;
}
.teams-url {
	flex-shrink: 0;
	background: url("../../src_static/assets/teams_icon.svg") no-repeat center;
	z-index: 10;
	pointer-events: auto;
	cursor: pointer;
	height: 30px;
	width: 30px;
}

.connect-via-title {
	font-size: 14px;
	padding-top: 2px;
}

.hr{
    color:#000000
}

.card_waywo_desc_editable:hover {
	text-decoration: none !important;
}


.role-based-carousel-container, .geo-based-carousel-container, .org-based-carousel-container {
	display: flex; align-items: center; justify-content: center; padding-top: 20px;
}

.role-based-carousel-item, .geo-based-carousel-item, .org-based-carousel-item {
	padding-left: 20px;
	display: none;
	padding-right: 20px;
	transition: opacity 0.5s ease-in-out;
}

.role-based-carousel-item.active, .geo-based-carousel-item.active, .org-based-carousel-item.active {
	display: block;
	opacity: 1;
}

.carousel-controls-container {
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.carousel-controls {
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.carousel-control {
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
}

.modal-card {
	min-height: 160px;
}

.carousel-control.left { left: 0; }
.carousel-control.right { right: 0; }

.role-based-slider, .geo-based-slider, .org-based-slider {
	position: relative;
	width: 1000px;
	height: 400px;
}



.role-based-slider-inner, .geo-based-slider-inner, .org-based-slider-inner {
	position: absolute;
	top: 0px;
	left: 0px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: grid !important;
	grid-template-columns: repeat(3, 1fr);
	gap: 5px;
	pointer-events: none;
	transition: 0s ease-in;

}

.slide-img {
	height: 100%;
	text-align: center;
	padding: 10px;
}

.category-title {
	padding-bottom: 0px;
	margin-top: 25px;
	font-size: 16px;
}


.lds-default,
.lds-default div {
	box-sizing: border-box;
}
.lds-default, .roles-based-lds-default, .geo-based-lds-default, .org-based-lds-default {
	position: relative;
	width: 80px;
	height: 80px;
	margin-top: 150px;
	margin-left: 450px;
}

.lds-default div {
	position: absolute;
	width: 6.4px;
	height: 6.4px;
	background: currentColor;
	border-radius: 50%;
	animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
	animation-delay: 0s;
	top: 36.8px;
	left: 66.24px;
}
.lds-default div:nth-child(2) {
	animation-delay: -0.1s;
	top: 22.08px;
	left: 62.29579px;
}
.lds-default div:nth-child(3) {
	animation-delay: -0.2s;
	top: 11.30421px;
	left: 51.52px;
}
.lds-default div:nth-child(4) {
	animation-delay: -0.3s;
	top: 7.36px;
	left: 36.8px;
}
.lds-default div:nth-child(5) {
	animation-delay: -0.4s;
	top: 11.30421px;
	left: 22.08px;
}
.lds-default div:nth-child(6) {
	animation-delay: -0.5s;
	top: 22.08px;
	left: 11.30421px;
}
.lds-default div:nth-child(7) {
	animation-delay: -0.6s;
	top: 36.8px;
	left: 7.36px;
}
.lds-default div:nth-child(8) {
	animation-delay: -0.7s;
	top: 51.52px;
	left: 11.30421px;
}
.lds-default div:nth-child(9) {
	animation-delay: -0.8s;
	top: 62.29579px;
	left: 22.08px;
}
.lds-default div:nth-child(10) {
	animation-delay: -0.9s;
	top: 66.24px;
	left: 36.8px;
}
.lds-default div:nth-child(11) {
	animation-delay: -1s;
	top: 62.29579px;
	left: 51.52px;
}
.lds-default div:nth-child(12) {
	animation-delay: -1.1s;
	top: 51.52px;
	left: 62.29579px;
}
@keyframes lds-default {
	0%, 20%, 80%, 100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
	}
}

.master-category-title {
	font-size: 20px
}

.category-title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.see-all-link {
	margin-right: 15px;
	margin-top: 25px;
	color: #5f60ff;
	font-size: 16px;
	cursor: pointer;
}
